import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import '../src/styles/index.css'

import { Flowbite } from 'flowbite-react';
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";

import Login from './pages/authentication/Login';
import Register from './pages/authentication/Register';

import App from './pages/app/App';
import Campsites from './pages/app/panels/Campsites';
import Create from './pages/app/panels/Create';
import Account from './pages/app/panels/Account';
import Landing from './pages/marketing/Landing';
import AccountDetails from './pages/authentication/onboarding/AccountDetails';

import { Modify } from './pages/app/panels/Modify';
import { UserProvider } from './internal/interfaces/Context';
import Terms from './pages/legal/Terms';
import Privacy from './pages/legal/Privacy';
import Forgot from './pages/authentication/forgot/Forgot';
import ResetPassword from "./pages/authentication/forgot/ResetPassword";

const router = createBrowserRouter([
  {
    path: '*',
    loader: () => { return redirect('/') }
  },
  {
    path: "/",
    element: <Landing/>,
  },
  {
    path: "/terms",
    element: <Terms/>,
  },
  {
    path: "/privacy",
    element: <Privacy/>,
  },
  {
    path: "/login",
    element: <Login/>,
  },
  {
    path: "/register",
    element: <Register/>,
    children: [
      {
        path: ":id",
        element: <AccountDetails/>
      }
    ]
  },
  {
    path: "/forgot",
    element: <Forgot/>,
    children: [
      {
        path: ":id",
        element: <ResetPassword />,
      }
    ]
  },
  {
    path: "/app/*",
    element: <App/>,
    children: [
      {
        path: "campsites",
        element: <Campsites/>,
        children: [
          {
            path: ":id",
            element: <Modify/>
          }
        ]
      },
      {
        path: "create",
        element: <Create/>,
      },
      {
        path: "account",
        element: <Account/>,
      }
    ]
  },
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// <React.StrictMode></>
root.render(
    <Flowbite>
      <UserProvider>
        <RouterProvider router={router}/>
      </UserProvider>
    </Flowbite>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
