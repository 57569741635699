import { NavigateFunction } from "react-router-dom";
import { getAxiosInstance } from "./AxiosInstance";
import { deleteCookie, getCookie } from "../internal/utilities/Token";

export async function handleLogout(navigate: NavigateFunction) {

    // Delete the cookies client-side so we aren't wasting server resources.
    // deleteCookie("refreshToken");
    // deleteCookie("token");

    const axios = getAxiosInstance();
    axios.post("/auth/logout", {})
        .then((resp) => {
            // if we made it this far we can navigate to /login
            navigate('/login');
        })
        .catch((error) => {
            alert('an error has occurred')
        })

    return true;
}