import { useContext, useState } from "react"

import {
    Alert,
    Button,
    Card,
    Datepicker,
    Label,
    ListGroup,
    TextInput,
} from "flowbite-react";

import Header from "../../../components/Header";
import { Container } from "../../../components/Container";

import { ParkSystems, UserContext } from "../../../internal/interfaces/Context";
import { handleCreate } from "../../../api/Create";
import { ICancellationData } from "../../../internal/interfaces/Cancellation";

import { motion } from "framer-motion";
import moment from "moment";

// If the tab isn't active, the color to be rendered is 'cyan'.
function Create() {

    const { userData, setUserData } = useContext(UserContext);

    const [activeParkSystem, setActiveParkSystem] = useState(0);
    const [activeRecreationArea, setActiveRecreationArea] = useState("");
    const [isSelectingCampsite, setIsSelectingCampsite] = useState(false);
    const [activeCampground, setActiveCampground] = useState(0);

    const [alertColor, setAlertColor] = useState("failure");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const currentDateInitialValue = new Date();
    const currentDatePlusOneInitalValue = new Date(currentDateInitialValue.setDate(currentDateInitialValue.getDate() + 1));

    const [currentDate, setCurrentDate] = useState(currentDateInitialValue);
    const [currentDatePlusOne, setCurrentDatePlusOne] = useState(currentDatePlusOneInitalValue);

    var activeRecArea = ParkSystems.map((parkSystem) => {
        return parkSystem.value.filter((recArea) => {
            return (recArea.name.toLowerCase() === activeRecreationArea.toLowerCase())
        })
    })[activeParkSystem][0];

    if (!userData) { return null };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >

            <div className="v-screen flex items-center justify-center">
                <Button.Group>
                    {
                        ParkSystems.map((value, idx) => {
                            return (
                                (idx === activeParkSystem) ? (
                                    <Button id={idx.toString()} onClick={() => {
                                        setActiveParkSystem(idx);
                                    }}>{value.name}</Button>
                                ) : (
                                    <Button id={idx.toString()} onClick={() => {
                                        setActiveParkSystem(idx);
                                    }} color="cyan">{value.name}</Button>
                                )
                            )
                        })
                    }
                </Button.Group>
            </div>

            <Container>
                {
                    (showAlert ? (
                        <Alert className="m-2" color={alertColor} onDismiss={() => { setShowAlert(false); }}>{alertMessage}</Alert>
                    ) : null)
                }

                <Container>
                    <Label htmlFor="searchCampgrounds">Campground</Label>

                    <TextInput
                        list="searchCampgrounds"
                        placeholder="Yoho - Lake O'Hara Backcountry"
                        onChange={(e) => {
                            setActiveRecreationArea(e.target.value);

                            // Reset campsite values they change the recArea value
                            setIsSelectingCampsite(false);
                            setActiveCampground(0);
                        }}
                    />
                    <datalist id="searchCampgrounds">
                        {
                            ParkSystems[activeParkSystem].value.map((recArea) => {
                                return (
                                    <option value={recArea.name}>{recArea.name}</option>
                                )
                            })
                        }
                    </datalist>
                </Container>

                {
                    (activeRecArea !== undefined) ? (
                        <Container>
                            <div className="rounded-lg bg-no-repeat bg-[url('https://images.unsplash.com/photo-1701221238491-d5247ff7abcc?q=80&w=2000&h=1050&auto=format&fit=crop')] h-66 sm:h-56 md:h-56 lg:h-44 bg-gray-600 bg-blend-multiply"
                            style={{
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                            }}>
  
                                <div className="mx-auto p-6">
                                    <h1 className="mb-4 text-2xl lg:text-3xl text-center font-extrabold tracking-tight leading-none text-white">{activeRecArea.name}</h1>
                                    <div className="h-40 sm:h-32 lg:h-20 overflow-y-auto lg:overflow-y-none">
                                        <p className="text-md lg:text-md font-normal text-gray-300">{activeRecArea.description}</p>
                                    </div>
                                </div>
                            </div>
                            <Card className="h-full" style={{
                                borderTopRightRadius: 0,
                                borderTopLeftRadius: 0,
                                borderTop: "none",
                                marginTop: 0,
                            }}>
                                <Button
                                    onClick={() => {
                                        setIsSelectingCampsite(!isSelectingCampsite);
                                    }}>Campsite Selection</Button>
                                {
                                    (isSelectingCampsite) ? (

                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: isSelectingCampsite ? 1 : 0 }}
                                            exit={{ opacity: 0 }}
                                        >
                                            <Header>
                                                Campsites
                                            </Header>

                                            <ListGroup className="max-h-52 overflow-y-auto mt-2">
                                                {
                                                    activeRecArea.campgrounds.map((campsite, idx) => {
                                                        const active = (activeCampground === idx);

                                                        return (
                                                            <ListGroup.Item active={active} onClick={() => {
                                                                setActiveCampground(idx);
                                                            }}>
                                                                {campsite.name}
                                                            </ListGroup.Item>
                                                        )
                                                    })
                                                }
                                            </ListGroup>
                                        </motion.div>
                                    ) : null
                                }

                                <Label htmlFor="dateSelector" className="text-md">Choose dates</Label>
                                <div className="grid grid-cols-11" id="dateSelector">
                                    <div className="col-span-5">
                                        <Datepicker
                                            id="startDate"
                                            minDate={currentDate}
                                            onSelectedDateChanged={(date) => {
                                                setCurrentDate(date);
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-1 flex justify-center items-center">
                                        <span className="align-text-bottom text-xl text-gray-500 dark:text-gray-400">-</span>
                                    </div>
                                    <div className="col-span-5">
                                        <Datepicker
                                            id="endDate"
                                            minDate={currentDate}
                                            defaultDate={currentDatePlusOne}
                                            onSelectedDateChanged={(date) => {
                                                setCurrentDatePlusOne(date);
                                            }}
                                        />
                                    </div>
                                </div>

                                <Button onClick={async () => {
                                    var cancellationToInsert = {
                                        userId: userData?._id,
                                        park: ParkSystems[activeParkSystem].name,
                                        startDate: moment(currentDate).format("YYYY-MM-DD"),
                                        endDate: moment(currentDatePlusOne).format("YYYY-MM-DD"),
                                        providerData: {
                                            ACC: {},
                                            Camis: {
                                                mapId: activeRecArea.mapId,
                                                resourceLocationId: activeRecArea.resourceLocationId,
                                                campgroundResourceId: isSelectingCampsite ? (activeRecArea.campgrounds[activeCampground].resourceId) : (0),
                                            }
                                        }
                                    } as ICancellationData;

                                    const result = await handleCreate(cancellationToInsert, userData, setUserData);
                                    const formattedMessage = result.message.charAt(0).toUpperCase() + result.message.slice(1) + ".";
                                    setAlertColor(result.alertColor);
                                    setAlertMessage(formattedMessage);
                                    setShowAlert(true);

                                }}>
                                    Add
                                </Button>

                            </Card>
                        </Container>
                    ) : null
                }

            </Container>

        </motion.div>
    )
}

export default Create;