import { NavigateFunction, useLocation } from "react-router-dom";
import { getAxiosInstance } from "./AxiosInstance";
import { AxiosInstance } from "axios";
import { IsTokenExpired, IsTokenNull } from "../internal/utilities/Token";

export async function refreshTokens() {

    const axiosInstance = getAxiosInstance();

    // Get token pair from backend
    const isRefreshed = await axiosInstance.post("/auth/refresh")
    .then((resp: any) => {
        if (resp.status === 200) {
            return true
        }
    })
    .catch((error: any) => {
        return error
    })

    return isRefreshed;
}

export async function isAuthenticated() {
    const axiosInstance = getAxiosInstance();

    // Post user data from backend
    const isAuthenticated = await axiosInstance.post('/account/data')
    .then((resp: any) => {

        // If we get a 200 status code from the API,
        // that means we are authenticated.
        if(resp.status === 200) {
            return true;
        }
    })
    .catch((error: any) => {
        
        if(error.message === 'Network Error') {
            // alert('Unable to connect. Please check your internet connection.')
            return false;
        }

        return false;
    })

    return isAuthenticated;
}

export const RedirectOnAuthentication = async(path: string, navigate: NavigateFunction) => {
    const authenticated = await isAuthenticated();

    if (!authenticated && path === "/app") {
        navigate('/login')
    } else if ((path === "/login" || path === "/register") && authenticated) {
        navigate('/app');
    }
}

export function RefreshTokenInterceptor(axiosInstance: AxiosInstance) {

    // Used to refresh the tokens when they expire
    const interceptor = axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {

            // Check if the auth token doesn't exist or it is expired,
            const authTokenExists = (IsTokenExpired("token") || IsTokenNull("token"))

            // Check that the refresh token exists and it isn't expired 
            const refreshTokenExists = (IsTokenExpired("refreshToken") && !IsTokenNull("refreshToken"))

            if (error.response && error.response.status == 401 && 
                (authTokenExists && refreshTokenExists)
            ) {
                try{
                    const result = await refreshTokens();
                    if(result) { return axiosInstance(error.config); }

                    return Promise.reject(error)
                }
                catch (err) {
                    return Promise.reject(err)
                }
            }
            return Promise.reject(error)
        },
    )

    return interceptor
}