import { useNavigate } from "react-router-dom";

export default function Logo() {
    const navigate = useNavigate();

    return (
        <span className="flex items-center mb-0 text-3xl font-semibold text-gray-900 dark:text-white m-auto" onClick={() => {
            navigate("/");
        }}>
            <img className="w-10 h-10 mr-2 shadow-lg" src="/logo.ico" alt="logo" />
            OpenCamp
        </span>
    )
}