import { getAxiosInstance } from "../AxiosInstance";

export async function handleAccountCreation(
    verifyPhoneId: string,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
) {
    const axiosInstance = getAxiosInstance();
    var message = "";
    var alertColor = "";
    var id = "";

    const success = await axiosInstance.post('/onboarding/create_account', {
        "verifyPhoneId": verifyPhoneId,
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "password": password,
    })
    .then((resp) => {
        message = resp.data.status;
        id = resp.data._id;
        alertColor = "success";

        return true;
    })
    .catch((error) => {
        const response = error.response;
        message = response.data.status;
        alertColor = "failure";

        return false;
    })

    return {
        success, message, alertColor, id
    };
}