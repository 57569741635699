import { useEffect, useState } from "react";
import { RedirectOnAuthentication } from "../../../api/Authenticator";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Checkbox, DarkThemeToggle, Label, TextInput } from "flowbite-react";

import { motion } from "framer-motion";
import { verifyPhoneID } from "../../../api/onboarding/VerifyPhoneID";
import { handleAccountCreation } from "../../../api/onboarding/HandleAccountCreation";

export default function AccountDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const [ showAlert, setShowAlert ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState("");
    const [ alertColor, setAlertColor ] = useState("");

    const [ firstName, setFirstName ] = useState("");
    const [ lastName, setLastName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");

    const [ agreedToTos, setAgreedToTos ] = useState(false);

    useEffect(() => {
        RedirectOnAuthentication(location.pathname, navigate);

        const RedirectIfNotValidPhoneID = async() => {
            if (!id) return null;

            const result = await verifyPhoneID(id, "onboarding")
            if (!result.success) {
                navigate('/register')
            }
        }
        RedirectIfNotValidPhoneID();

    }, [])


    if (!id) { return null; }

    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {
                showAlert ? (
                    <Alert color={alertColor} className="mb-4" onDismiss={() => { setShowAlert(!showAlert); }}>{alertMessage}</Alert>
                ) : null
            }
            <form className="flex flex-col gap-4" onSubmit={async (event) => {
                event.preventDefault();
                
                const result = await handleAccountCreation(
                    id, firstName, lastName, email, password,
                );

                result.message = result.message.charAt(0).toUpperCase() + result.message.slice(1);

                if(!result.success) { result.message += "." }

                setAlertColor(result.alertColor)
                setAlertMessage(result.message)
                setShowAlert(true);
            }}>
                <div className="flex flex-wrap -mx-3">

                    <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                        <div className="mb-2 block">
                            <Label htmlFor="firstName" value="Your first name" />
                        </div>
                        <TextInput id="firstName" type="text" placeholder="John" onChange={(event) => { setFirstName(event.target.value); }} required />
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <div className="mb-2 block">
                            <Label htmlFor="lastName" value="Your last name" />
                        </div>
                        <TextInput id="lastName" type="text" placeholder="Doe" onChange={(event) => { setLastName(event.target.value); }} required />
                    </div>
                </div>

                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="email" value="Your email" />
                    </div>
                    <TextInput id="email" type="email" placeholder="john@opencamp.app" onChange={(event) => { setEmail(event.target.value); }} required />
                </div>

                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="password" value="Your password" />
                        <div className="mb-4 mt-2 font-medium text-sm dark:text-gray-300">
                            Password must be greater than 8 characters, no greater than 16 characters, contain 1 number, 1 capital letter, and 1 special character.
                        </div>
                    </div>
                    <TextInput
                        id="password"
                        type="password"
                        placeholder='••••••••'
                        pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$"
                        onChange={(event) => { setPassword(event.target.value); }}
                        required
                    />
                </div>

                <div className="flex items-center justify-between gap-2">
                    <div>
                        <Checkbox id="remember" color="purp" onChange={() => { setAgreedToTos(!agreedToTos); }} />
                        <Label htmlFor="remember" className='mx-1 text-xs'>
                            I agree to the
                            <span
                                className="text-xs font-medium text-cyan-500 hover:underline dark:text-cyan-400" onClick={() => {
                                    navigate("/terms", { replace: true })
                                }}> Terms of Service </span>
                            and
                            <span
                                className="text-xs font-medium text-cyan-500 hover:underline dark:text-cyan-400" onClick={() => {
                                    navigate("/privacy", { replace: true })
                                }}> Privacy Policy.</span>
                        </Label>
                    </div>
                </div>

                <Button type="submit" disabled={!agreedToTos}>Register</Button>

            </form>
        </motion.div>
    );
}