import { Alert, Button, Dropdown, Modal, TextInput } from "flowbite-react";
import { useState } from "react";
import Flag from 'react-world-flags';
import { motion } from 'framer-motion';
import { sendPhoneVerificationCode } from "../api/onboarding/SendPhoneVerification";
import { verifyPhoneNumber } from "../api/onboarding/VerifyPhoneNumber";

interface PhoneInputProps {
    id: string;
    type: string;
    onChange?: (
        phoneNumber: string,
        areaCode: string,
    ) => void,
    onSubmit: (
        valid: boolean,
        phoneNumber: string,
        areaCode: string,
        id: string,
    ) => void,
}

function PhoneInput(props: PhoneInputProps) {
    const [currentCountryIdx, setCurrentCountryIdx] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertColor, setAlertColor] = useState("");

    const [hasSentCode, setHasSentCode] = useState(false);

    const [verificationCode, setVerificationCode] = useState("");

    const defaultPattern = {
        pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
        placeholder: '123-456-7890'
    }

    const countryData = [
        {
            countryCode: 1,
            label: "Canada (+1)",
            flagIdentifier: "ca",
            validation: defaultPattern
        },
        {
            countryCode: 1,
            label: "United States (+1)",
            flagIdentifier: "us",
            validation: defaultPattern
        },
        {
            countryCode: 44,
            label: "United Kingdom (+44)",
            flagIdentifier: "gb",
            validation: {
                pattern: '[0-9]{4}-[0-9]{3}-[0-9]{3}',
                placeholder: '1234-567-890'
            }
        },
        {
            countryCode: 33,
            label: "France (+33)",
            flagIdentifier: "fr",
            validation: {
                pattern: '[0-9]{1}-[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}',
                placeholder: '1-23-45-67-89'
            }
        },
        {
            countryCode: 49,
            label: "Germany (+49)",
            flagIdentifier: "de",
            validation: defaultPattern
        },
    ]

    return (
        <div>
            {
                showAlert ? (
                    <Alert color={alertColor} className="mb-4 mt-4" onDismiss={() => { setShowAlert(!showAlert); }}>{alertMessage}</Alert>
                ) : null
            }
            <form className="w-full mx-auto" id="form1" action="#" onError={(error) => {
            }} onSubmit={async (event) => {
                event.preventDefault();

                const areaCode = countryData[currentCountryIdx].countryCode.toString();
                const response = await sendPhoneVerificationCode(phoneNumber, areaCode, "onboarding");

                // Show the alert
                setAlertColor(response.alertColor);
                setAlertMessage(response.message)
                setShowAlert(true);

                if (response.success) {
                    setHasSentCode(!hasSentCode);
                }

            }}>
                <div className="flex items-center">
                    <div style={{
                        color: "white",
                        maxWidth: "5rem"
                    }}>
                        <Dropdown
                            id="phoneVerificationDropdown"
                            dismissOnClick={true}
                            label={"+" + countryData[currentCountryIdx].countryCode.toString()}
                            className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700"
                            style={{
                                maskType: "luminance",
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                            }}
                            placement="top"
                        >
                            {
                                countryData.map((phoneCode, idx) => {
                                    return (
                                        <Dropdown.Item id={phoneCode.countryCode.toString()} onClick={() => {

                                            setCurrentCountryIdx(idx)

                                            // Callback function
                                            if (props.onChange) {
                                                props.onChange(phoneNumber, phoneCode.countryCode.toString())
                                            }
                                        }}>
                                            <span className="inline-flex items-center" id={phoneCode.flagIdentifier}>
                                                <Flag code={phoneCode.flagIdentifier} width="16" className="mx-1 rounded-sm" />
                                                {phoneCode.label}
                                            </span>
                                        </Dropdown.Item>
                                    )
                                })
                            }

                        </Dropdown>
                    </div>


                    <TextInput
                        id="phone-input"
                        type="text"
                        form="form1"
                        pattern={countryData[currentCountryIdx].validation.pattern}
                        placeholder={countryData[currentCountryIdx].validation.placeholder}
                        className="relative w-full rounded-e-lg border-s-0"
                        style={{
                            borderRadius: 0,
                        }}
                        value={phoneNumber}
                        onChange={(event) => {
                            setPhoneNumber(event.target.value);

                            // Callback function
                            if (props.onChange) {
                                props.onChange(phoneNumber, countryData[currentCountryIdx].countryCode.toString())
                            }
                        }}
                        required
                    />


                    <Button
                        type="submit"
                        form="form1"
                        className="z-10 divide-y divide-gray-100 rounded-lg shadow w-52"
                        style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}
                        onSubmit={(e: any) => console.log(e)}
                    >Send</Button>
                </div>
            </form>

            {
                (hasSentCode) ? (
                    
                    <Modal
                        show={hasSentCode}
                        onClose={() => { setHasSentCode(false); }}
                    >
                        <motion.div
                            key="modalAnimation"
                            initial={{ opacity: 0.2 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0.2 }}
                        >
                            <Modal.Header>Verification code</Modal.Header>
                            <Modal.Body>
                                {
                                    showAlert ? (
                                        <Alert color={alertColor} className="mb-4" onDismiss={() => { setShowAlert(!showAlert); }}>{alertMessage}</Alert>
                                    ) : null
                                }
                                <TextInput id="phoneVerificationCode" placeholder="000000" pattern="[0-9]{6}" onChange={(event) => {
                                    setVerificationCode(event.target.value);
                                }} required />

                                <Button
                                    id="phoneVerificationCode2"
                                    form="phoneVerificationForm"
                                    onClick={async () => {
                                        const areaCode = countryData[currentCountryIdx].countryCode.toString();

                                        if (verificationCode.length <= 0) {
                                            setAlertMessage("You must type in a verification code.")
                                            setAlertColor("failure")
                                            setShowAlert(true);
                                            return;
                                        }

                                        const response = await verifyPhoneNumber(
                                            areaCode, phoneNumber, verificationCode, props.type
                                        );

                                        setAlertMessage(response.message);
                                        setAlertColor(response.alertColor);
                                        setShowAlert(true);

                                        props.onSubmit(response.success, phoneNumber, areaCode, response.id);
                                        if (response.success) {
                                            setHasSentCode(false);
                                        }

                                    }}
                                    className="mt-3 rounded-lg shadow w-full"
                                >Verify</Button>

                            </Modal.Body>
                        </motion.div>
                    </Modal>
                ) : null
            }
        </div>

    )
}

export default PhoneInput;