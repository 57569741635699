import { ICancellationData } from "../internal/interfaces/Cancellation";
import { IUserData } from "../internal/interfaces/user/User";
import { getAxiosInstance } from "./AxiosInstance";

export default function OnDeleteCancellation(
    cancellation: ICancellationData,
    userData: IUserData,
    setUserData: (userData: IUserData) => void,
) {

    const axiosInstance = getAxiosInstance();

    const result = axiosInstance.post("/cancellation/delete", {
        "objectId": cancellation.objectId,
    })
    .then((resp: any) => {
        if(resp.status === 200) {
            // Find the index of the cancellation
            var cancellationIdx = userData.cancellations.findIndex(function(id) {
                return id.objectId == cancellation.objectId;
            });
            
            // Remove it from the array
            userData.cancellations.splice(cancellationIdx, 1);

            // Update the userData object
            // Spread the object so it updates the dom
            setUserData({...userData});
        }

        return true;
    })
    .catch((error: any) => {
        return false;
    })

    return result
}