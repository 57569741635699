import Logo from "../../../components/Logo";

import { motion } from 'framer-motion';
import {Alert, Button, Card, Label, Spinner, TextInput} from "flowbite-react";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {handleSendPasswordResetEmail} from "../../../api/SendPasswordResetEmail";

function RequestPasswordToken() {

    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState("");

    const [alertColor, setAlertColor] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [apiFetching, setApiFetching] = useState(false);

    return (
        <div>
            {
                showAlert ? (
                    <Alert color={alertColor}>
                        <span className="font-medium">{alertMessage}</span>
                    </Alert>
                ) : null
            }

            <form className="flex flex-col gap-4" onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {

                // TODO - Implement functionality

                // Prevent the form from refreshing the page
                event.preventDefault();

                // Change the button state.
                setApiFetching(true);

                // Attempt to login the user
                const {success, message, alertColor} = await handleSendPasswordResetEmail(email);
                setAlertMessage(message);
                setAlertColor(alertColor);
                setShowAlert(true);

                // After we've fetched the data, reset the button state.
                setApiFetching(false);

                //if (success) {
                //   setTimeout(() => {
                //      navigate('/app')
                // }, 1000);
                //}
            }}>

                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="email1" value="Your email" />
                    </div>
                    <TextInput id="email1" type="email" placeholder="user@opencamp.app" onChange={(event) => {
                        setEmail(event.target.value);
                    }} required />
                </div>

                <Button type="submit"> { (apiFetching) ? (<Spinner size="sm" className='mx-2' /> ) : ("Request Reset") }</Button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Already have an account? <span className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" onClick={() => {
                    navigate("/login", { replace: true })
                }}>Login</span>
                </p>
            </form>
        </div>
    )
}

export default RequestPasswordToken;