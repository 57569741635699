import { getAxiosInstance } from "../AxiosInstance";

export async function verifyPhoneNumber(
    areaCode: string,
    phoneNumber: string,
    verificationCode: string,
    type: string,
) {
    const axiosInstance = getAxiosInstance();
    var message = "";
    var alertColor = "";
    var id = "";

    var toPost = "";
    if (type === "onboarding") {
        toPost = "/onboarding/verify";
    }
    else {
        toPost = "/account/update/phone_number"
    }

    const success = await axiosInstance.post(toPost, {
        "code": verificationCode,
        "phoneNumber": phoneNumber,
        "areaCode": areaCode,
    })
    .then((resp) => {
        message = resp.data.status;
        id = resp.data._id;
        alertColor = "success";

        return true;
    })
    .catch((error) => {
        if (error.message === "Network Error") {
            message = "failed to connect to phone verification service";
        } else {
            message = error.response.data.status;
        }

        return false;
    })

    return {
        success, message, alertColor, id
    };
}