import Logo from "../../../components/Logo";

import { motion } from 'framer-motion';
import {Alert, Button, Card, Label, Spinner, TextInput} from "flowbite-react";
import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {handleResetPassword} from "../../../api/ResetPassword";

function ResetPassword() {

    const navigate = useNavigate();
    const { id } = useParams();

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [alertColor, setAlertColor] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [apiFetching, setApiFetching] = useState(false);

    useEffect(() => {
        // Check if the token is valid with an API call,
        // If it isn't, redirect the user.

    }, []);

    if (!id) return null;

    return (
        <div>
            {
                showAlert ? (
                    <Alert color={alertColor}>
                        <span className="font-medium">{alertMessage}</span>
                    </Alert>
                ) : null
            }

            <form className="flex flex-col gap-4" onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {

                // Prevent the form from refreshing the page
                event.preventDefault();

                // Change the button state.
                setApiFetching(true);

                // Perform the request
                const { success, message, alertColor} = await handleResetPassword(id, newPassword);
                setAlertMessage(message);
                setAlertColor(alertColor);
                setShowAlert(true);

                // After we've fetched the data, reset the button state.
                setApiFetching(false);

                if (success) {
                   setTimeout(() => {
                      navigate('/app')
                 }, 1000);
                }
            }}>

                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="newPassword" value="New password"/>
                    </div>
                    <TextInput id="newPassword" type="password" placeholder="New password" onChange={(event) => {
                        setNewPassword(event.target.value);
                    }} required/>
                </div>

                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="confirmPassword" value="Confirm password"/>
                    </div>
                    <TextInput id="confirmPassword" type="password" placeholder="Confirm password" onChange={(event) => {
                        setConfirmPassword(event.target.value);
                    }} required/>
                </div>

                <Button type="submit"> {(apiFetching) ? (
                    <Spinner size="sm" className='mx-2'/>) : ("Reset Password")}</Button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Already have an account? <span
                    className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" onClick={() => {
                    navigate("/login", {replace: true})
                }}>Login</span>
                </p>
            </form>
        </div>
    )
}

export default ResetPassword;