import { Badge, Button, DarkThemeToggle, Footer, Sidebar } from "flowbite-react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useContext, useLayoutEffect, useState } from "react";

import Campsites from "./panels/Campsites";
import Create from "./panels/Create";
import Account from "./panels/Account";
import { Modify } from "./panels/Modify";

import { AnimatePresence } from 'framer-motion';

import { Container } from "../../components/Container";
import { UserContext } from "../../internal/interfaces/Context";

import { GetUserData } from "../../api/Account";
import { RedirectOnAuthentication } from "../../api/Authenticator";
import HasActiveSubscription from "../../internal/utilities/Subscription";
import { handleLogout } from "../../api/Logout";

import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

function getActiveTab(location: string) {
    if (!location) return null;
    else {
        switch (location) {
            case "campsites":
                return 0;
            case "create":
                return 1;
            case "account":
                return 2;
        }
    }
}

function App() {

    const navigate = useNavigate();
    const location = useLocation();

    function Banner() {
        return (
            <div>
                <a className="flex items-center mb-4 text-2xl md:text-lg lg:text-2xl font-semibold text-gray-900 dark:text-white m-auto">
                    <img className="w-10 h-10 mr-2 rounded shadow-lg" src="/logo.ico" alt="logo" />
                    <div>
                        OpenCamp
                    </div>
                    <Button className="mx-2 block md:hidden" color="red" onClick={() => { setSidebarActive(false); }}>
                        <IoClose size={14} />
                    </Button>
                </a>

                <div className="mt-2 mb-2">
                    {
                        isSubscribed ? (
                            <div className="flex flex-wrap gap-1 mt-2">
                                <Badge color="success" className="shadow">Premium</Badge>
                            </div>
                        ) : (
                            <div className="flex flex-wrap gap-1 mt-2">
                                <Badge color="failure" className="shadow">Inactive</Badge>
                            </div>
                        )
                    }
                    <div className="mt-2">
                        <span className="text-sm text-gray-700 dark:text-gray-300">
                            Welcome back, {userData?.firstName}.
                        </span>
                    </div>

                    <div className="mb-1 inline-block mt-2">
                            <DarkThemeToggle className="align-middle" />
                            <span className="font-bold text-sm text-gray-700 dark:text-gray-500 align-middle mx-2">Toggle Theme</span>
                        </div>
                </div>
            </div>
        )
    }

    const { userData, setUserData } = useContext(UserContext);
    const isSubscribed = HasActiveSubscription(userData);

    const [sidebarActive, setSidebarActive] = useState(false);

    useLayoutEffect(() => {
        RedirectOnAuthentication(location.pathname, navigate);
        GetUserData(setUserData);
    }, [])

    if (!userData) return null;

    const tabName = location.pathname.split('/')[2];

    // If there is not second path supplied in the route,
    // just set it to 0.
    var activeTab = getActiveTab(tabName);
    if (!activeTab) {
        activeTab = 0;
    }

    const tabs = [
        {
            label: "Campsites",
            link: "/app/campsites",
        },
        {
            label: "Create",
            link: "/app/create",
        },
        {
            label: "Account",
            link: "/app/account"
        }
    ]

    return (
        <section className='bg-gray-50 dark:bg-gray-900 min-h-screen h-auto overflow-auto'>
            <div className="px-5 pt-10 md:px-30 lg:px-10">
                <div className="grid grid-cols-5 auto-cols-max gap-3">
                    <div className="col-span-5 md:col-span-1">

                        <div className="hidden md:block">
                            <Banner/>
                        </div>

                        {
                            (sidebarActive) ? (
                                <Sidebar className="block md:hidden min-h-[100dvh] absolute z-20 rounded-4xl left-0 top-0 shadow-lg">
                                    <Sidebar.Items>
                                        <Sidebar.ItemGroup>
                                            
                                            <Banner/>

                                            {
                                                tabs.map((tab, idx) => {
                                                    return (
                                                        <Sidebar.Item onClick={() => {
                                                            navigate(tab.link);
                                                        }} active={idx === activeTab} color="indigo">
                                                            {tab.label}
                                                        </Sidebar.Item>
                                                    )
                                                })
                                            }
                                            <Sidebar.Item onClick={() => {
                                                handleLogout(navigate);
                                            }}>
                                                Logout
                                            </Sidebar.Item>
                                        </Sidebar.ItemGroup>
                                    </Sidebar.Items>
                                </Sidebar>
                            ) : (null)
                        }

                        {
                            // Render all of the tabs, and make the active one colored.
                            tabs.map((tab, idx) => {

                                if (activeTab === idx) {
                                    return (
                                        <Button className="shadow w-full mb-3 p-1 hidden md:flex" onClick={() => {
                                            navigate(tab.link);
                                        }}>{tab.label}</Button>
                                    )
                                } else {
                                    return (
                                        <Button color="dark" className="shadow w-full mb-3 p-1 hidden md:flex" onClick={() => {
                                            navigate(tab.link);
                                        }}>{tab.label}</Button>
                                    )
                                }
                            })
                        }
                        <Button color="dark" className="w-full p-1 hidden md:flex" onClick={() => { handleLogout(navigate); }}>Logout</Button>
                    </div>

                    <div className="col-span-5 md:col-span-4 align-top">

                        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 md:text-3xl dark:text-white z-0">

                        <Button className="mb-4 block md:hidden" onClick={() => { setSidebarActive(true); }}>
                            <FiMenu size={18}/>
                        </Button>

                            {tabs[activeTab].label.charAt(0).toUpperCase() + tabs[activeTab].label.slice(1)}
                        </h1>

                        <Container>
                            <AnimatePresence>
                                <Routes>
                                    <Route index element={<Campsites />} />
                                    <Route path="/campsites" element={<Campsites />} />
                                    <Route path="/campsites/:id" element={<Modify />} />
                                    <Route path="/create" element={<Create />} />
                                    <Route path="/account" element={<Account />} />
                                </Routes>
                            </AnimatePresence>
                        </Container>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default App;