import { Badge, Button, Card, Label, TextInput } from "flowbite-react";
import Header from "../../../components/Header";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../internal/interfaces/Context";
import HasActiveSubscription from "../../../internal/utilities/Subscription";

import { motion } from "framer-motion";
import PhoneInput from "../../../components/PhoneInput";

import { MdSms } from "react-icons/md";
import { changePassword } from "../../../api/onboarding/ChangePassword";
import { getAxiosInstance } from "../../../api/AxiosInstance";
import Checkout from "../../../components/Checkout";

import config from "../../../config.json";

// Setup Stripe
const STRIPE_API_KEY = config.PRODUCTION ? config.STRIPE.PROD_KEY : config.STRIPE.TEST_KEY;
const stripeInstance = loadStripe(STRIPE_API_KEY);

function Account() {

    const { userData, setUserData } = useContext(UserContext);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [clientSecret, setClientSecret] = useState("pi_default_secret_default");;

    const [isFetchingClientSecret, setIsFetchingClientSecret] = useState(true);

    useEffect(() => {

        // get clientSecret for Stripe
        if(!userData) return;
        
        const axiosInstance = getAxiosInstance();
        axiosInstance.post("/payment/create_intent")
        .then((resp) => {
            if (resp.status === 200) {
                setClientSecret(resp.data.client_secret)
            }
            setIsFetchingClientSecret(false);
        })
        .catch((error) => {
            console.log(error)
            setIsFetchingClientSecret(false);
        })

    }, [])
    
    if (!userData) { return null; }
    if (isFetchingClientSecret) { return (
        <div>Loading client secret...</div>
    ) }

    const isSubscribed = HasActiveSubscription(userData);

    return(
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="grid gap-4">
                    <Card className="max-w-full">
                        <div className="flex flex-wrap gap-2">
                            <Header>
                                Update Phone Number
                            </Header>
                            <Badge color="info" icon={MdSms} className="mt-1 text-xs rounded-md w-fit shadow outline-style: dashed">
                                +{userData.areaCode} {userData.phoneNumber}
                            </Badge>
                        </div>
                        <PhoneInput
                            id="phone-input"
                            type="account"
                            onSubmit={(valid, phoneNumber, areaCode, id) => {
                                // Handle phone number response
                            }}
                        />
                    </Card>

                    <Card className="max-w-full">
                        <div>
                            <Header>
                                Update Password
                            </Header>

                            <form onSubmit={async (event) => {

                                event.preventDefault();

                                // Handle password reset
                                if (newPassword != confirmNewPassword) {
                                    console.log("passwords must match")
                                    return
                                }

                                const result = await changePassword(currentPassword, newPassword);
                                console.log(result)

                            }}>

                                <div className="mb-2 mt-3">
                                    <div className="mb-2 block">
                                        <Label htmlFor="currentPassword" value="Current password" />
                                    </div>
                                    <TextInput id="currentPassword" type="password" placeholder='••••••••' onChange={(event) => {
                                        setCurrentPassword(event.target.value);
                                    }} required />
                                </div>

                                <div className="mb-2 mt-3">
                                    <div className="mb-2 block">
                                        <Label htmlFor="newPassword" value="New password" />
                                    </div>
                                    <TextInput id="newPassword" type="password" placeholder='••••••••' onChange={(event) => {
                                        setNewPassword(event.target.value);
                                    }} required />
                                </div>

                                <div className="mb-4 mt-3">
                                    <div className="mb-2 block">
                                        <Label htmlFor="confirmNewPassword" value="Confirm password" />
                                    </div>
                                    <TextInput id="confirmNewPassword" type="password" placeholder='••••••••' onChange={(event) => {
                                        setConfirmNewPassword(event.target.value);
                                    }} required />
                                </div>
                                
                                <Button type="submit" className="w-full">Update</Button>

                            </form>
                        </div>
                    </Card>
                </div>
                <div className="max-w-full">
                    <Card>
                        <Header>
                            Subscription
                            {
                                isSubscribed ? (
                                    <div className="flex flex-wrap gap-1 mt-2">
                                        <Badge color="success" className="shadow">Premium</Badge>
                                    </div>
                                ) : (
                                    <div className="flex flex-wrap gap-1 mt-2">
                                        <Badge color="failure" className="shadow">Inactive</Badge>
                                    </div>
                                )
                            }

                        </Header>

                        {
                            isSubscribed ? (
                                <div className="text-sm font-medium text-gray-900 dark:text-gray-300">
                                Your subscription will expire on {
                                        new Date(userData.subscription.expiryDate).toLocaleDateString("en-US")
                                    } at {
                                        new Date(userData.subscription.expiryDate).toLocaleTimeString("en-US")
                                    }.
                                </div>
                            ) : (
                                <Elements stripe={stripeInstance} options={{
                                    clientSecret: clientSecret,
                                }}>
                                    <Checkout/>
                                </Elements>
                            )
                        }
                    </Card>
                </div>
            </div>
        </motion.div>
    )
}

export default Account;