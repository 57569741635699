import { IUserData } from "../internal/interfaces/user/User";
import { getAxiosInstance } from "./AxiosInstance";

export async function GetUserData(setUserData: (userData: IUserData) => void) {
    const axiosInstance = getAxiosInstance();

    const response = await axiosInstance.post('/account/data')
    .then((resp: any) => {
        setUserData(resp.data);
        return true;
    })
    .catch((resp: any) => {
        return false;
    })

    return response;
}