import { Button } from "flowbite-react";
import { handleSubmitPayment } from "../api/SubmitPayment";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useContext } from "react";
import { UserContext } from "../internal/interfaces/Context";

export default function Checkout() {
    const stripe = useStripe();
    const elements = useElements()

    const { userData, setUserData } = useContext(UserContext);
    if (!userData) return null;

    return (
        <div>
            <div className="bg-gray-50 px-5 py-5 bg-white dark:bg-gray-100 rounded-xl dark:border-solid border-2 dark:border-cyan-400 shadow-lg">
                <div>
                    <div className="text-lg font-medium text-gray-700">
                        OpenCamp - 1 Month
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                        $4.99 CAD
                    </div>
                    <div className="text-xs font-medium text-gray-400 mt-1">
                        Does not auto renew each month.
                    </div>
                </div>

                <div className="mt-3">

                    <PaymentElement className="" />

                    <div className="text-xs font-medium text-gray-400 mt-4">
                        By making a payment, you agree to Stripe's
                        <a target="_blank" href="https://stripe.com/en-ca/legal/ssa" className="text-cyan-500"> Terms of Service </a>
                        and
                        <a target="_blank" href="https://stripe.com/en-ca/privacy" className="text-cyan-500"> Privacy Policy.</a>
                    </div>
                </div>
            </div>

            <Button className="w-full mt-4" onClick={() => {
                if (!stripe || !elements) { return null; }

                handleSubmitPayment(stripe, elements, userData, setUserData);
            }}>Pay</Button>
        </div>
    )
}