import '../../../styles/App.css'
import '../../../styles/index.css'

import { Card } from 'flowbite-react';
import { motion } from "framer-motion";

import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../../components/Logo';
import RequestPasswordToken from "./RequestPasswordToken";
import ResetPassword from "./ResetPassword";

import { RedirectOnAuthentication } from '../../../api/Authenticator';

function Forgot() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        RedirectOnAuthentication(location.pathname, navigate);
    }, [])

    return (
        <section className='bg-gray-50 dark:bg-gray-900 min-h-screen'>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                <div className="flex flex-col items-center min-h-screen p-10">

                    <Logo/>

                    <Card className="max-w-sm mb-auto mt-8 w-full drop-shadow-md" style={{
                        maxWidth: "50rem",
                    }}>

                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Password Reset
                        </h1>

                        <Routes>
                            <Route index element={<RequestPasswordToken/>}/>
                            <Route path={ "/:id" } element={<ResetPassword/>}/>
                        </Routes>

                    </Card>


                </div>
            </motion.div>
        </section>
    );
}

export default Forgot;
