import { ICancellationData } from "../internal/interfaces/Cancellation";
import { IUserData } from "../internal/interfaces/user/User";
import { getAxiosInstance } from "./AxiosInstance";

export async function handleCreate(
    cancellation: ICancellationData,
    userData: IUserData,
    setUserData: (userData: IUserData) => void,
) {
    const axiosInstance = getAxiosInstance();
    var message = "";
    var alertColor = "";

    const success = await axiosInstance.post('/cancellation/add', cancellation)
    .then((resp) => {
        message = resp.data.status;
        cancellation.objectId = resp.data.objectId;
        alertColor = "success";

        // Update the user object so we can render to the dom
        userData.cancellations.push(cancellation);

        // Update the userData object
        // Spread the object so it updates the dom
        setUserData({...userData});

        return true;
    })
    .catch((error) => {
        const response = error.response;
        message = response.data.status;
        alertColor = "failure";

        return false;
    })

    return {
        success, message, alertColor
    };
}