import { getAxiosInstance } from "../AxiosInstance";

export async function verifyPhoneID(
    verifyId: string,
    type: string,
) {
    const axiosInstance = getAxiosInstance();
    var message = "";
    var alertColor = "";
    var id = "";

    const success = await axiosInstance.post('/' + type + '/get', {
        "id": verifyId,
    })
    .then((resp) => {
        message = resp.data.status;
        id = resp.data._id;
        alertColor = "success";

        return true;
    })
    .catch((error) => {
        const response = error.response;
        message = response.data.status;
        message = "an error has occurred"
        alertColor = "failure";

        return false;
    })

    return {
        success, message, alertColor, id
    };
}