import { getAxiosInstance } from "../AxiosInstance";

export async function changePassword(
    currentPassword: string,
    newPassword: string,
) {
    const axiosInstance = getAxiosInstance();

    const success = await axiosInstance.post('/account/update/password', {
        'currentPassword': currentPassword,
        'newPassword': newPassword,
    })
    .then((resp) => {
        console.log(resp);
    })
    .catch((error) => {
        console.log(error.response.data.status);
    })

    return success
}