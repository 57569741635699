import { ICancellationData } from "../internal/interfaces/Cancellation";
import { IUserData } from "../internal/interfaces/user/User";
import { getAxiosInstance } from "./AxiosInstance";

export async function handleSendPasswordResetEmail(
    email: string,
) {
    const axiosInstance = getAxiosInstance();
    let message = "";
    let alertColor = "";

    const success = await axiosInstance.post('/forgot/request/password', {
        "email": email.toLowerCase(),
    })
        .then((resp) => {
            message = resp.data.status;
            alertColor = "success";

            return true;
        })
        .catch((error) => {
            const response = error.response;
            message = response.data.status;
            alertColor = "failure";

            return false;
        })

    return {
        success, message, alertColor
    };
}