import { getAxiosInstance } from "./AxiosInstance";

export async function handleLogin(email: string, password: string) {
    const axiosInstance = getAxiosInstance();
    var message = "";
    var alertColor = "";
    
    const success = await axiosInstance.post('/auth/login', {
        // Convert the email to lowercase
        'email': email.toLowerCase(),
        'password': password,
    })
    .then((resp) => {
        message = resp.data.status;
        alertColor = "success";

        return true;
    })
    .catch((error) => {
        if (error.message === "Network Error") {
            message = "failed to connect to authentication service";
        } else {
            message = error.response.data.status;
        }

        alertColor = "failure";

        return false;
    })

    return [success, message, alertColor];
}