import axios, { AxiosInstance } from "axios"
import { RefreshTokenInterceptor } from "./Authenticator";

import config from "../config.json";

// Setup API url
const API_URL = config.PRODUCTION ? config.API.PROD_URL : config.API.DEV_URL;

export function getAxiosInstance() {

    const axiosInstance: AxiosInstance = axios.create({
        baseURL: API_URL,
        withCredentials: true,
    });

    RefreshTokenInterceptor(axiosInstance);

    return axiosInstance;
}