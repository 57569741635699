import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {Alert, Breadcrumb, Button, Card, Datepicker, Label} from "flowbite-react";

import { FaMapLocationDot, FaCampground } from "react-icons/fa6";
import { MdForest } from "react-icons/md";

import Header from "../../../components/Header";
import { Container } from "../../../components/Container";

import { ParkSystems, UserContext } from "../../../internal/interfaces/Context";
import { ICancellationData } from "../../../internal/interfaces/Cancellation";
import ConvertStringToDate from "../../../internal/utilities/DateString";

import { motion } from "framer-motion";
import {getAxiosInstance} from "../../../api/AxiosInstance";
import moment from "moment/moment";

export type ModifyProps = {
    cancellation: ICancellationData
}

export function Modify() {
    const navigator = useNavigate();
    
    const { userData, setUserData } = useContext(UserContext);
    const { id } = useParams();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [alertColor, setAlertColor] = useState("failure");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);


    const cancellation = userData?.cancellations.filter(cancellation => {
        return cancellation.objectId === id
    })[0];

    if(!cancellation) {
        navigator('/app');
        return null;
    };

    const recreationAreaIdx = ParkSystems.findIndex((element) => {
        return element.name === cancellation.park
    })

    // Find the campsite object from cancellationData that matches the one we're receiving
    // Used to render titles and descriptions based on the mapId
    const recreationAreaObject = ParkSystems[recreationAreaIdx].value.find((data) => {
       return data.mapId === cancellation.providerData.Camis.mapId;
    });
    
    const campsiteObject = recreationAreaObject?.campgrounds.find((data) => {
        return data.resourceId === cancellation.providerData.Camis.campgroundResourceId
    })

    var isCampsite = true;

    if (!campsiteObject) {
        isCampsite = false;
    }

    return(
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <Container>

                <Breadcrumb className="bg-white px-5 py-3 dark:bg-gray-800 rounded mb-3 border-solid border dark:border-gray-700 shadow" onClick={() => {
                    navigator("/app/campsites")
                }} style={{cursor: "pointer"}}>
                    <Breadcrumb.Item icon={FaMapLocationDot}>
                        {cancellation.park}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item icon={MdForest}>
                        {recreationAreaObject?.name}
                    </Breadcrumb.Item>
                    { 
                    (isCampsite) ? (
                        <Breadcrumb.Item icon={FaCampground}>
                            {campsiteObject?.name}
                        </Breadcrumb.Item>
                    ) : null }
                </Breadcrumb>

                {
                    (showAlert ? (
                        <Alert className="m-2" color={alertColor} onDismiss={() => { setShowAlert(false); }}>{alertMessage}</Alert>
                    ) : null)
                }

                <Card className="h-full">
                    <Header>
                        Modify
                    </Header>
                    
                    <Label htmlFor="dateSelector" className="text-md">Choose dates</Label>
                    <div className="grid grid-cols-11" id="dateSelector">
                        <div className="col-span-5">
                            <Datepicker id="startDate"
                                        onSelectedDateChanged={(date) => {
                                            setStartDate(date);
                                        }}
                                        defaultDate={
                                            new Date(ConvertStringToDate(cancellation.endDate))
                                        }>
                            </Datepicker>
                        </div>
                        <div className="col-span-1 flex justify-center items-center">
                            <span className="align-text-bottom text-xl text-gray-500 dark:text-gray-400">-</span>
                        </div>
                        <div className="col-span-5">
                            <Datepicker id="endDate"
                                        onSelectedDateChanged={(date) => {
                                            setEndDate(date);
                                        }}
                                        defaultDate={
                                            new Date(ConvertStringToDate(cancellation.endDate))
                                        }>
                            </Datepicker>
                        </div>
                    </div>

                    <Button onClick={() => {
                        // make the request to the api with the data
                        const axiosInstance = getAxiosInstance();
                        const result = axiosInstance.post("/cancellation/modify", {
                            "id": id,
                            "updatedStartDate": moment(startDate).format("YYYY-MM-DD"),
                            "updatedEndDate": moment(endDate).format("YYYY-MM-DD"),
                        })
                            .then((resp) => {
                                console.log(resp.data)
                                setAlertColor("success");
                                setAlertMessage(resp.data.status);
                            })
                            .catch((error: any) => {
                                setAlertColor("failure");
                                setAlertMessage(error.response.data.status);
                                console.log("ballsack");
                            })

                        setShowAlert(true);

                    }}>
                        Update
                    </Button>

                </Card>
            </Container>
        </motion.div>
    )
}