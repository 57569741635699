import { createContext, useState } from "react";
import { IUserData, IUserDataProps } from "./user/User";

import campsiteData from "../../static/campsite-data.json";

export const ParkSystems = [
    { name: "Parks Canada", value: campsiteData.Parks_Canada }, 
    { name: "BC Parks", value: campsiteData.BC_Parks },
];

export const UserContext = createContext<IUserDataProps>({
    userData: null,
    setUserData: () => null
});

export const UserProvider : React.FC<{children: React.ReactNode}> = ({children}) => {
    const [userData, setUserData] = useState<IUserData | null>(null);

    const values = {
        userData,
        setUserData
    };

    return (
        <UserContext.Provider value={values}>
            {children}
        </UserContext.Provider>
    )
};