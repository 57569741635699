import '../../styles/App.css'
import '../../styles/index.css'

import {
    Breadcrumb,
    Card,
} from 'flowbite-react';

import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../../components/Logo';
import { useEffect } from 'react';
import { RedirectOnAuthentication } from '../../api/Authenticator';

import { FiSmartphone } from "react-icons/fi";
import { MdAccountCircle } from "react-icons/md";
import { FcCheckmark } from "react-icons/fc";
import PhoneVerification from './onboarding/PhoneVerification';
import AccountDetails from './onboarding/AccountDetails';


function Register() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        RedirectOnAuthentication(location.pathname, navigate);
    }, [])

    return (
        <section className='bg-gray-50 dark:bg-gray-900 min-h-screen'>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <div className="flex flex-col items-center min-h-screen p-10">

                    <Logo />

                    <Card className="max-w-sm mb-auto mt-8 w-full drop-shadow-md" style={{
                        maxWidth: "50rem",
                    }}>

                        <Breadcrumb className="bg-gray-50 px-5 py-3 bg-white dark:bg-gray-800 rounded mb-3 border-solid border dark:border-gray-700 shadow" onClick={() => {
                            navigate("/register")
                        }}>
                            <Breadcrumb.Item icon={FiSmartphone}>
                                Verify Phone Number
                            </Breadcrumb.Item>
                            <Breadcrumb.Item icon={MdAccountCircle}>
                                Account Information
                            </Breadcrumb.Item>
                            <Breadcrumb.Item icon={FcCheckmark} className='invisible md:visible'>
                                Complete
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        <Routes>
                            <Route index element={ <PhoneVerification/>} />
                            <Route path="/:id" element={ <AccountDetails/> }/>
                        </Routes>
                    </Card>
                </div>
            </motion.div>
        </section>
    );
}

export default Register;
