import { getAxiosInstance } from "../AxiosInstance";

export async function sendPhoneVerificationCode(
    phoneNumber: string,
    areaCode: string,
    type: string,
) {
    const axiosInstance = getAxiosInstance();
    var message = "";
    var alertColor = "";
    var id = "";

    const success = await axiosInstance.post('/' + type + '/sms', {
        "phoneNumber": phoneNumber,
        "areaCode": areaCode,
    })
    .then((resp) => {
        message = resp.data.status;
        id = resp.data._id;

        alertColor = "success";

        return true;
    })
    .catch((error) => {
        if (error.message === "Network Error") {
            message = "failed to connect to phone verification service";
        } else {
            message = error.response.data.status;
        }

        alertColor = "failure";

        return false;
    })

    return {
        success, message, alertColor, id
    };
}