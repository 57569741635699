import { useElements, useStripe } from "@stripe/react-stripe-js";
import { IUserData } from "../internal/interfaces/user/User";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import {getAxiosInstance} from "./AxiosInstance";

export async function handleSubmitPayment(
    stripe: Stripe,
    elements: StripeElements,
    userData: IUserData,
    setUserData: (userData: IUserData) => void,
) {
    if (!stripe || !elements) return;

    const axiosInstance = getAxiosInstance();

    // Let's process the payment
    const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
            // CHANGE ME
            return_url: "http://127.0.0.1:5000/app",
        },
        redirect: 'if_required'
    });

    if (error) {
        alert(error.message);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        alert('Payment success!');

        // we need to update the claims to make the client authenticated for subscription only routes
        await axiosInstance.post("/auth/refresh")
            .then((resp) => {
                console.log("refreshed token");
            })
            .catch((err) => {
                alert("failed to refresh token");
            })

        // Update the local userData object
        const currentDate = new Date();
        const plusOneMonthDate = currentDate.setDate(currentDate.getDate() + 30);        
        userData.subscription.expiryDate = new Date(plusOneMonthDate);

        setUserData({...userData});
    }
    else {
        alert('Payment failed.');
    }
    
}