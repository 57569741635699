import { Button, Card } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { ParkSystems, UserContext } from "../../../internal/interfaces/Context";
import { useContext, useState } from "react";
import OnDeleteCancellation from "../../../api/Campsites";

import notFoundImage from "../../../static/oc-unavailable.png";

import { motion } from "framer-motion";

function Campsites() {

    const navigator = useNavigate();

    const { userData, setUserData } = useContext(UserContext);

    var isCampsite = true;

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            {
                userData?.cancellations.length === 0 ? (
                    <div>
                        <div className="mb-1 dark:text-white">
                            You currently have no active notifiers.
                        </div>
                        <a className="text-cyan-500 font-medium" onClick={() => { navigator('/app/create'); }}>Click here to create one!</a>
                    </div>
                ) : null
            }
            {
                userData?.cancellations.map((cancellation) => {

                    const recreationAreaIdx = ParkSystems.findIndex((element) => {
                        return element.name === cancellation.park
                    })

                    // Find the campsite object from cancellationData that matches the one we're receiving
                    // Used to render titles and descriptions based on the mapId
                    const recreationAreaObject = ParkSystems[recreationAreaIdx].value.find((data) => {
                       return data.mapId === cancellation.providerData.Camis.mapId;
                    });
                    

                    const campsiteObject = recreationAreaObject?.campgrounds.find((data) => {
                        return data.resourceId === cancellation.providerData.Camis.campgroundResourceId
                    })

                    return(
                        <Card
                            imgSrc={(campsiteObject?.photos[0]) ? (campsiteObject?.photos[0]) : (notFoundImage)}
                            style={{
                                maxWidth: "100%",
                            }}
                            className="mb-3"
                            horizontal
                        >
                            <div>
                                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    {recreationAreaObject?.name}
                                </h5>
                                {
                                    (isCampsite) ? (
                                        <div>
                                            <span className="font-normal text-md text-gray-500 dark:text-indigo-500">{campsiteObject?.name}</span>
                                        </div>
                                    ) : null
                                }
                                <span className="font-normal text-sm text-gray-700 dark:text-gray-500">
                                    {cancellation.startDate} to {cancellation.endDate}
                                </span>
                            </div>
                            <p className="font-normal text-gray-700 dark:text-gray-400 max-h-20 overflow-y-auto">
                                {recreationAreaObject?.description}
                            </p>
                            <Button color="gray" onClick={() => {
                                navigator('/app/campsites/' + cancellation.objectId)
                            }}>Modify</Button>
                            <Button color="failure" onClick={() => { OnDeleteCancellation(cancellation, userData, setUserData) }}>Delete</Button>
                        </Card>
                    )
                })
            }
        </motion.div>
)
}

export default Campsites;