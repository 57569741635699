import { Button, Label } from "flowbite-react";
import PhoneInput from "../../../components/PhoneInput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import { sendPhoneVerificationCode } from "../../../api/onboarding/SendPhoneVerification";

export default function PhoneVerification() {

    const navigate = useNavigate();
    const [nextButtonState, setNextButtonState] = useState(true);
    const [nextStepId, setNextStepId] = useState("");

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div id="onboarding">

                <div className="mb-2 block">
                    <Label htmlFor="phone-input" value="Your phone number" />
                </div>

                <div className='mb-4'>

                    <PhoneInput
                        id="phone-input"
                        type="onboarding"
                        onChange={(phoneNumber, areaCode) => {
                            // console.log(phoneNumber, areaCode)
                        }}
                        onSubmit={(async (success, phoneNumber, areaCode, id) => {
                            if (success) {
                                setNextButtonState(!success);
                                setNextStepId(id);
                            }
                        })}
                    />
                    
                </div>

                <Button form="phoneVerificationStep" className='mw-full w-full mt-3' disabled={nextButtonState} onClick={() => {
                    navigate('/register/' + nextStepId)
                }}>Next</Button>

                <p className="text-sm font-light text-gray-500 dark:text-gray-400 mt-3">
                    Already have an account? <span className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" onClick={() => {
                        navigate("/login", { replace: true })
                    }}>Login</span>
                </p>
            </div>
        </motion.div>

    )
}