import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import { Accordion, Badge, Button, Card, DarkThemeToggle } from "flowbite-react";
import { Checkmark, EmptyCheckmark } from "../../components/Checkmark";

function Landing() {
    const navigate = useNavigate();

    const style = {
        background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%,rgba(0, 0, 0, 0.4) 100%), url('https://images.unsplash.com/photo-1587381419691-1593e4a122fb?q=80&w=2070&h=1000&auto=format&fit=crop')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    };

    return (
        <div className="bg-gray-50 dark:bg-gray-900 min-h-[100dvh]">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <header className="h-auto bg-landing-img shadow-2xl">
                    <nav className="bg-transparent border-gray-200 px-4 lg:px-6 py-2.5 pt-6 text-gray-100" color="transparent">
                        <div className="flex justify-center mt-4 lg:order-2 gap-3">
                            <Button onClick={() => {
                                navigate('/login');
                            }}>
                                Login
                            </Button>
                            <Button onClick={() => {
                                navigate('/register');
                            }}>
                                Sign Up
                            </Button>
                            <DarkThemeToggle />
                        </div>
                        <div className="text-center mt-24 font-medium text-4xl">
                            <div className="flex flex-wrap justify-center items-center mx-auto max-w-screen-xl sticky">
                                <a href="#" onClick={() => { navigate('/') }} className="flex items-center">
                                    <img src="/logo.ico" className="mr-3 h-12 sm:h-10" alt="OpenCamp Icon" />
                                    <span className="self-center text-4xl font-semibold whitespace-nowrap dark:text-white">OpenCamp</span>
                                </a>
                            </div>
                        </div>
                        <div className="text-center mt-4 font-light text-xl">
                            An easier way to get campsite cancellations.
                        </div>
                        <div className="text-center mt-4 font-medium mb-24">
                            For only 5$ a month, you can get unlimited text notifications for campsite cancellations, every 5 minutes.
                        </div>
                    </nav>
                </header>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-8">

                    <div className="justify-center">
                        <div className="mt-8 text-3xl dark:text-gray-200 text-center font-medium">
                            Pricing
                        </div>
                        <Card className="mt-8">
                            <h5 className="text-xl font-medium text-gray-500 dark:text-gray-400">Premium</h5>
                            <div className="flex items-baseline text-gray-900 dark:text-white">
                                <span className="text-3xl font-semibold">$</span>
                                <span className="text-5xl font-extrabold tracking-tight">5</span>
                                <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">/month</span>
                            </div>
                            <span className="w-fit">
                                <Badge color="success">Free 1 Week Trial</Badge>
                            </span>
                            <ul className="space-y-5">
                                <li className="flex space-x-2">
                                    <Checkmark />
                                    <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Unlimited notifiers</span>
                                </li>
                                <li className="flex space-x-2">
                                    <Checkmark />
                                    <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Campsite scans every 5 minutes</span>
                                </li>
                                <li className="flex space-x-2">
                                    <Checkmark />
                                    <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Email support</span>
                                </li>
                            </ul>
                            <Button className="drop-shadow-xl mt-2" onClick={() => { navigate('/register'); }}>
                                Get Started
                            </Button>
                        </Card>
                    </div>

                    <div className="justify-center mt-8">
                        <div className="text-3xl dark:text-gray-200 text-center font-medium">
                            FAQ
                        </div>

                        <Accordion collapseAll={true} className="mt-8">
                            
                        <Accordion.Panel>
                                <Accordion.Title>
                                    What does OpenCamp do?
                                </Accordion.Title>
                                <Accordion.Content className="dark:text-gray-200">
                                Each season, numerous popular campgrounds witness bookings made months in advance, only to encounter subsequent cancellations.
                                OpenCamp diligently monitors these campgrounds for reservation cancellations, promptly notifying you upon availability.
                                </Accordion.Content>
                            </Accordion.Panel>

                            <Accordion.Panel>
                                <Accordion.Title>
                                    What parks do you currently support?
                                </Accordion.Title>
                                <Accordion.Content className="dark:text-gray-200">
                                    OpenCamp is presently in the process of implementing its services within Canadian parks.
                                    <div className="mt-2 mb-1 text-2xl font-medium">
                                        Supported Parks
                                    </div>
                                    <ul className="space-y-2">
                                        <li className="flex space-x-1">
                                            <Checkmark />
                                            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Parks Canada</span>
                                        </li>
                                        <li className="flex space-x-1">
                                            <Checkmark />
                                            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">BC Parks</span>
                                        </li>
                                    </ul>
                                    <div className="mt-4 mb-1 text-2xl font-medium">
                                        Parks Being Implemented
                                    </div>
                                    <ul className="space-y-2">
                                        <li className="flex space-x-1">
                                            <EmptyCheckmark />
                                            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Alpine Club of Canada Huts</span>
                                        </li>
                                        <li className="flex space-x-1">
                                            <EmptyCheckmark />
                                            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Alberta Parks</span>
                                        </li>
                                        <li className="flex space-x-1">
                                            <EmptyCheckmark />
                                            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Ontario Parks</span>
                                        </li>
                                        <li className="flex space-x-1">
                                            <EmptyCheckmark />
                                            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Yukon</span>
                                        </li>
                                        <li className="flex space-x-1">
                                            <EmptyCheckmark />
                                            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">New Brunswick</span>
                                        </li>
                                        <li className="flex space-x-1">
                                            <EmptyCheckmark />
                                            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Newfoundland & Labrador</span>
                                        </li>
                                    </ul>

                                </Accordion.Content>
                            </Accordion.Panel>

                            <Accordion.Panel>
                                <Accordion.Title>
                                    How does OpenCamp compare to other websites alike?
                                </Accordion.Title>
                                <Accordion.Content className="dark:text-gray-200">
                                Numerous web applications with a similar focus tend to offer limited features at significantly higher prices. Our commitment lies
                                in ensuring that our pricing structure and feature set are closely aligned with the actual costs associated with hosting such a
                                service. OpenCamp has been meticulously crafted with users like myself in consideration, aiming to deliver the most cost-effective
                                and user-friendly experience for booking campsite cancellations.
                                </Accordion.Content>
                            </Accordion.Panel>

                            <Accordion.Panel>
                                <Accordion.Title>
                                    What is the backstory behind OpenCamp?
                                </Accordion.Title>
                                <Accordion.Content className="dark:text-gray-200">
                                    While living in Banff National Park last year, I encountered challenges securing specific campsites within the park.
                                    Disappointed by the unavailability of a campsite in the Lake O'Hara Backcountry due to an unsuccessful raffle earlier
                                    in the year, I embarked on creating a solution. Several weeks later, having successfully secured multiple campground
                                    reservations at Lake O'Hara within a span of two days using a prototype, OpenCamp was created.
                                </Accordion.Content>
                            </Accordion.Panel>

                        </Accordion>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default Landing;