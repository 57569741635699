import { jwtDecode } from "jwt-decode";

const isDeveloping = true;
const domain = isDeveloping ? "127.0.0.1" : "opencamp.app";

export function getCookie(name: string): string | null {
	const nameLenPlus = (name.length + 1);
	return document.cookie
		.split(';')
		.map(c => c.trim())
		.filter(cookie => {
			return cookie.substring(0, nameLenPlus) === `${name}=`;
		})
		.map(cookie => {
			return decodeURIComponent(cookie.substring(nameLenPlus));
		})[0] || null;
}

export function deleteCookie( name: string ) {
    if( getCookie( name ) ) {
      document.cookie = name + "=" +
        (";path=/") +
        (";domain=" + domain) +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }

export function IsTokenExpired(name: string) {
    const token = getCookie(name)
    if (token === null) { return true; }

    const decoded = jwtDecode(token);
    if (!decoded.iat) return true;
    
    return decoded.iat > Date.now();
}

export function IsTokenNull(name: string) {
    const token = getCookie(name)
    if (token === null) {
        return true;
    }
    return false;
}