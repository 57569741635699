import '../../styles/App.css'
import '../../styles/index.css'

import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Card, Checkbox, Label, Spinner, TextInput } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { handleLogin } from '../../api/Login';
import { RedirectOnAuthentication } from '../../api/Authenticator';

import { motion } from "framer-motion";
import Logo from '../../components/Logo';


function Login() {
    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [alertColor, setAlertColor] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [apiFetching, setApiFetching] = useState(false);

    useEffect(() => {
        RedirectOnAuthentication(location.pathname, navigate);
    }, [])

    return (
        <section className='bg-gray-50 dark:bg-gray-900 min-h-screen'>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <div className="flex flex-col items-center min-h-screen">
                    
                    <Logo/>

                    <Card className="max-w-sm mb-auto mt-8 w-full drop-shadow-md">
                        {
                            showAlert ? (
                                <Alert color={alertColor}>
                                    <span className="font-medium">{alertMessage}</span>
                                </Alert>
                            ) : null
                        }

                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Login to your account
                        </h1>
                        <form className="flex flex-col gap-4" onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {

                            // Prevent the form from refreshing the page
                            event.preventDefault();

                            // Change the button state.
                            setApiFetching(true);

                            // Attempt to login the user
                            const [success, message, alertColor] = await handleLogin(email, password);
                            setAlertMessage(message.toString());
                            setAlertColor(alertColor.toString());
                            setShowAlert(true);

                            // After we've fetched the data, reset the button state.
                            setApiFetching(false);

                            if (success) {
                                setTimeout(() => {
                                    navigate('/app')
                                }, 1000);
                            }
                        }}>

                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="email1" value="Your email" />
                                </div>
                                <TextInput id="email1" type="email" placeholder="user@opencamp.app" onChange={(event) => {
                                    setEmail(event.target.value);
                                }} required />
                            </div>

                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="password1" value="Your password" />
                                </div>
                                <TextInput id="password1" type="password" placeholder='••••••••' onChange={(event) => {
                                    setPassword(event.target.value);
                                }} required />
                            </div>

                            <div className="flex items-center justify-between gap-2">
                                <div>
                                    <Checkbox id="remember" color="purp" />
                                    <Label htmlFor="remember" className='mx-1 text-xs'>Remember me</Label>
                                </div>

                                <span className="text-xs font-medium text-cyan-500 hover:underline dark:text-cyan-400" onClick={() => {
                                    navigate("/forgot", { replace: true })
                                }}>Forgot password?</span>
                            </div>
                            <Button type="submit"> { (apiFetching) ? (<Spinner size="sm" className='mx-2' /> ) : ("Login") }</Button>
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                Don’t have an account yet? <span className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" onClick={() => {
                                    navigate("/register", { replace: true })
                                }}>Sign up</span>
                            </p>
                        </form>
                    </Card>
                </div>
            </motion.div>
        </section>
    );
}

export default Login;
